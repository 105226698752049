import React, { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const PrivateRoute = ({ children, role }) => {
    const location = useLocation();
    const [isVerified, setIsVerified] = useState(false);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            setHasError(true);
            return;
        }

        fetch('/backend/verifToken.php', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json())
            .then(data => {
                if (data.error) {
                    setHasError(true);
                } else {
                    setIsVerified(true);
                }
            })
            .catch(() => setHasError(true));
    }, []);

    if (hasError) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    if (!isVerified) {
        return <div>Loading...</div>; // or a loading spinner
    }

    const userRole = localStorage.getItem('role');
    if (userRole !== role) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
};

export default PrivateRoute;
