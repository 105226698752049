import React, {useEffect, useRef, useState} from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    ToggleButton
} from '@mui/material';
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Logout from "./logout";
import Calendar from "./calendar";
import Presets  from "./presets";



function Dashboard() {

    const [instance , setInstance] = useState(null);
    const [calendarRef,setCalendarRef] = useState(null);
    // dictionary with key = student_id and value = array of dates
    const [days, setDays] = useState({});
    const [students, setStudents] = useState({});


    useEffect(() => {
        document.title = 'Dashboard';
        //const [dates,datesFriendly] = getWeekDates(selectedDate);
        //setWeekDates(dates);
        //setWeekDatesFriendly(datesFriendly);
        //console.log(weekDates);
        getStudents();
        // Function to fetch vacation days

        //updateVacationDays().then(r => setVacationDays(vacationDays));
    }, []);

    const updateCalendarData = async (student_id) => {
        const childDays = await getDays(student_id);
        setDays(prevDays => {
            // Only update if there is a change
            if (JSON.stringify(prevDays[student_id]) !== JSON.stringify(childDays)) {
                return { ...prevDays, [student_id]: childDays };
            }
            return prevDays;
        });
    };



    const handleDateUpdate = async (student_id, date, action) => {
        const response = await fetch('/backend/restauration/API.php', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ student_id, date, action }),
        });
        const data = await response.json();
        if (data === 'ok') {
            console.log(`Day ${action}:`, date);
            return updateDaysState(student_id, date, action);
        }
    };

    function updateDaysState(student_id, date, action) {
        setDays(currentDays => {
            const updatedDays = [...(currentDays[student_id] || [])];
            if (action === 'add') {
                updatedDays.push(date);
            } else if (action === 'delete') {
                const index = updatedDays.indexOf(date);
                if (index > -1) updatedDays.splice(index, 1);
            }
            return { ...currentDays, [student_id]: updatedDays };
        });
        return Promise.resolve(days[student_id]);
    }


    const getStudents = async () => {
        const response = await fetch('/backend/garderie/getStudents.php', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ user_id: localStorage.getItem('user_id') })
        });
        const studentsGet = await response.json();

        const newDays = {};
        for (const child of studentsGet) {
            students[child.ID] = child;
            const childDays = await getDays(child.ID);
            newDays[child.ID] = childDays;
        }
        setDays(newDays);
    };


    const getDays = async (student_id) => {
        const response = await fetch('/backend/restauration/API.php', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ student_id: student_id, action: 'get' })
        });
        const data = await response.json();
        return data || [];
    };


    function onNewDateSelected(date, student_id) {
        if (days[student_id].includes(date)) {
            return handleDateUpdate(student_id, date, 'delete');
        } else {
            return handleDateUpdate(student_id, date, 'add');
        }
    }


    return (
        <>
            <Logout />
            <h1>Dashboard</h1>
            <h2>Enfants</h2>
            {Object.keys(days).map((ID) => (
                <div key={ID}>
                    <h3>{students[ID].name} {students[ID].firstName}</h3>
                    <Presets student_id={ID} presets={students[ID].presets} onUpdate={() => updateCalendarData(ID)}></Presets>
                    <Calendar
                        student_id={ID}
                        instanceRef={setCalendarRef}
                        onNewDateSelected={onNewDateSelected}
                        days={days[ID]}
                    />
                </div>
            ))}
        </>
    );

}


export default Dashboard;