import Flatpickr from "react-flatpickr";
import React, {useEffect, useRef, useState} from "react";
import {format, isBefore, addDays, subDays} from 'date-fns';

const France = require("flatpickr/dist/l10n/fr.js").default.fr;

function Calendar({onNewDateSelected, days, student_id, holidays}) {
    const [selectedDate, setSelectedDate] = useState(days || []);
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [vacationDays, setVacationDays] = useState(
        JSON.parse(localStorage.getItem('holidays')) || []
    );
    const flatpickrRef = useRef(null);
    useEffect(() => {
        // Fetch vacation days if not already available
        if (!vacationDays.length || vacationDays[0].year !== currentYear) {
            updateVacationDays(currentYear);
        }
        if (flatpickrRef.current && flatpickrRef.current.flatpickr) {
            // Update the selected dates in Flatpickr

            if (flatpickrRef.current.flatpickr.selectedDates.length !== days.length) {
                flatpickrRef.current.flatpickr.setDate(days, false); // The second parameter 'true' is to trigger a change event
            }
        }
        flatpickrRef.current.flatpickr.jumpToDate(new Date());
        setSelectedDate(days);

    }, [currentYear, days]);

    const handleDateChange = (selectedDates, dateStr, instance) => {

        // Simplified date change logic
        const newSelectedDates = selectedDates.map(date => format(date, 'yyyy-MM-dd'));
        // Only update selected dates is different from days prop
        /* if (newSelectedDates.every(element => days.includes(element))) {
             return;
         }*/

        const clickedDay = newSelectedDates.find(date => !selectedDate.includes(date))
            || selectedDate.find(date => !newSelectedDates.includes(date));
        if (clickedDay) {
            onNewDateSelected(clickedDay, student_id)
                .catch(console.error)
                .finally(() => setSelectedDate(newSelectedDates));
        }
    };

    const handleMonthChange = async (selectedDates, dateStr, instance) => {
        setCurrentYear(instance.currentYear);
        if (instance.currentMonth === 8 && !vacationDays.some(v => v.year === instance.currentYear)) {
            await updateVacationDays(instance.currentYear);
        }
        instance.set('disable', [
            date => isDisabledDate(date, vacationDays),
        ]);
    };

    const isDisabledDate = (date, vacationDays) => {
        const day = date.getDay();
        return day === 0 || day === 6 || day === 3 ||
            vacationDays.some(vacation => isWithinVacationRange(date, vacation)) || isBefore(date, new Date());
    };

    const isWithinVacationRange = (date, vacation) => {
        return isBefore(date, new Date(vacation.to)) && isBefore(new Date(vacation.from), date);
    };

    async function updateVacationDays(year) {
        const vacations = await fetchVacationDays(7, year);
        const vacationsWithYear = vacations.map(v => ({...v, year}));
        setVacationDays(vacationsWithYear);
        localStorage.setItem('holidays', JSON.stringify(vacationsWithYear));
    }

    async function fetchVacationDays(MONTH, YEAR) {
        // Simplified and error-handled fetch logic
        try {
            const annee_scolaire = MONTH > 8 ? `${YEAR}-${YEAR + 1}` : `${YEAR - 1}-${YEAR}`;
            const url = `https://data.education.gouv.fr/api/explore/v2.1/catalog/datasets/fr-en-calendrier-scolaire/records?limit=20&refine=location%3A%22Toulouse%22&refine=population%3A%22-%22&refine=annee_scolaire%3A%22${annee_scolaire}%22`;
            const response = await fetch(url);
            const data = await response.json();
            return data.results.map(vacation => ({
                from: format(new Date(vacation.start_date), 'yyyy-MM-dd'),
                to: format(subDays(new Date(vacation.end_date), 1), 'yyyy-MM-dd')
            }));
        } catch (error) {
            console.error('Error fetching vacation data:', error);
        }
    }

    return (
        <Flatpickr
            ref={flatpickrRef}
            onChange={handleDateChange}
            onMonthChange={handleMonthChange}
            style={{display: 'none'}}
            options={{
                disable: [
                    date => isDisabledDate(date, vacationDays),
                ],
                locale: France,
                dateFormat: "Y-m-d",
                inline: true,
                mode: "multiple",
            }}
        />
    );
}

export default Calendar;

// Helper function to use in other modules
export function getHolidaysFromStorage() {
    return JSON.parse(localStorage.getItem('holidays')) || [];
}