import { useNavigate } from 'react-router-dom';

const Logout = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        // Clear authentication token or other related data
        localStorage.removeItem('token');
        localStorage.removeItem('role');

        // Redirect to login or home page
        navigate('/login');
    };

    return (
        <button onClick={handleLogout}>Logout</button>
    );
};

export default Logout;