import React, {useEffect, useRef, useState} from "react";
import {Button} from "@mui/material";
import "./presets.css";

function Presets({student_id,presets,onUpdate}) {
    const [days, setDays] = useState(parseInt(presets));
    const isInitialMount = useRef(true);
    const [lastDaysClicked, setLastDaysClicked] = useState(null);

    const handleClick = (event) => {
        const dayElement = event.target;
        const dayValue = parseInt(dayElement.getAttribute('data-day'), 10);
        const isActivated = dayElement.getAttribute('data-activated') === 'true';

        console.log("click value", dayValue);

        if (isActivated) {
            setDays(prevDays => prevDays - dayValue); // Ensures subtraction
            dayElement.setAttribute('data-activated', 'false');
            setLastDaysClicked(dayValue.toString());
        } else {
            setDays(prevDays => prevDays + dayValue); // Ensures addition
            dayElement.setAttribute('data-activated', 'true');
        }

    };


    function binaryDaysToArray(binaryDays) {
        const days = ["Monday", "Tuesday", "Thursday", "Friday"];
        let result = [];

        days.forEach((day, index) => {
            // Check if the bit for this day is set
            if (binaryDays & (1 << index)) {
                result.push(day);
            }
        });

        return result;
    }

    function getAllDatesOfAWeekdayUntilNextJuly14(weekday) {
        let today = new Date();
        const currentYear = today.getFullYear();
        const nextYear = currentYear + 1;
        let thisYearJuly14 = new Date(`July 14, ${currentYear}`);
        let nextYearJuly14 = new Date(`July 14, ${nextYear}`);
        let endDate = (today > thisYearJuly14) ? nextYearJuly14 : thisYearJuly14;

        // Use holidays from props or fetch from localStorage
        const localHolidays =  JSON.parse(localStorage.getItem('holidays'));

        let dates = [];
        while (today <= endDate) {
            if (today.toLocaleString('en-US', { weekday: 'long' }) === weekday) {
                if (!isHoliday(today, localHolidays)) {
                    dates.push(today.toISOString().split('T')[0]);
                }
                today.setDate(today.getDate() + 7); // Move to the next week
            } else {
                today.setDate(today.getDate() + 1);
            }
        }

        return dates;
    }

    function isHoliday(date, holidays) {
        return holidays.some(holiday => {
            const fromDate = new Date(holiday.from);
            const toDate = new Date(holiday.to);
            return date >= fromDate && date <= toDate;
        });
    }


    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            updateDataBase();
        }
    }, [days]);

    async function updateDataBase() {
        let dayOfWeek = "null";
        let dates = [];
        let daysOfWeek = binaryDaysToArray(days);
        if (days !== 0) {
            daysOfWeek.forEach(day => {
                dates = dates.concat(getAllDatesOfAWeekdayUntilNextJuly14(day));
            });
        }
        if (lastDaysClicked !== null) {
            dayOfWeek = binaryDaysToArray(lastDaysClicked)[0];
        }
        console.log(days);
        fetch('/backend/restauration/API.php', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({student_id : student_id,dayDelete: dayOfWeek,dates:dates,presets:days, action: "presets"})
        })
            .then(response => response.json())
            .then(data => {
                console.log(data);
                setLastDaysClicked(null);
                onUpdate();
            })
            .catch(error => console.error('Erreur:', error));

    }

    // presets int binary to array of binary days (1,2,4,8)
    useEffect(() => {
        if (presets !== null) {
            let presetsBinary = parseInt(presets);
            [1, 2, 4, 8].forEach(day => {
                if ((presetsBinary & day) === day) {
                    document.querySelector(`[data-day="${day}"]`).setAttribute('data-activated', 'true');
                }
            });
        }
    }, []);


    return (
        <div>
            <Button variant="outlined" data-day="1" onClick={handleClick} data-activated="false">Lundi</Button>
            <Button variant="outlined" data-day="2" onClick={handleClick} data-activated="false">Mardi</Button>
            <Button variant="outlined" data-day="4" onClick={handleClick} data-activated="false">Jeudi</Button>
            <Button variant="outlined" data-day="8" onClick={handleClick} data-activated="false">Vendredi</Button>
        </div>

    );
}
export default Presets;