import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
function AutoCompleteInput({ isActive, creneau, selectedDate,onValueChange  }) {
    const [inputValue, setInputValue] = useState('');
    const [listStudent, setListStudent] = useState([]);
    const [addStudent, setAddStudent] = useState(null);

    useEffect(() => {
        if (!isActive || !selectedDate || !creneau) return;

        fetch('http://localhost:80/garderie/API.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ date: selectedDate, action: "filter", creneau: creneau })
        })
            .then(response => response.json())
            .then(data => {
                setListStudent(data);
            })
            .catch(error => console.error('Erreur:', error));
    }, [selectedDate, creneau, isActive]);

    const handleChange = (event, newValue) => {
        setAddStudent(newValue);
        if (onValueChange) {
            onValueChange(newValue); // Appeler la fonction callback avec la nouvelle valeur
        }
    };

    return isActive ? (
        <Autocomplete
            disablePortal
            id="add"
            options={listStudent}
            sx={{ height: 300 , width: 300}}
            value={addStudent}
            onChange={handleChange}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => <TextField {...params} label="Chercher l'élève" />}
            renderOption={(props, option) => (
                <li {...props}>
                    {option.name} {option.firstName}
                </li>
            )}
            getOptionLabel={(option) => `${option.name} ${option.firstName}`}
        />
    ) : null;
}

export default AutoCompleteInput;
