import React, {useEffect, useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Checkbox from '@mui/material/Checkbox';
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import AutoCompleteInput from './AutoCompleteInput';
import Logout from './logout';
import {Box, Slider, ToggleButton, ToggleButtonGroup} from "@mui/material"; // Assurez-vous que le chemin est correct
const France = require("flatpickr/dist/l10n/fr.js").default.fr;

class Eleve {
    constructor(id, nom, prenom, classe) {
        this.id = id;
        this.nom = nom;
        this.prenom = prenom;
        this.classe = classe;
    }

}

function StudentTable() {
    const [students, setStudents] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [openDialogRetard, setOpenDialogRetard] = useState(false);
    const [openDialogAjout, setOpenDialogAjout] = useState(false);
    const [checked, setChecked] = useState(false);
    const [studentSeleted, setStudentSeleted] = useState('');
    const [retard, setRetard] = useState(0);
    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
    const [creneau, setCreneau] = useState('MATIN');
    const [ACI, setACI] =useState(false);
    const [addStudent, setAddStudent] =useState(null);


    // load on startup
    useEffect(() => {
        document.title = "Dashboard Admin"
        loadStudentList(selectedDate,creneau);
    }, [selectedDate,creneau]);




    const handleChangeButton = (event, newAlignment) => {
        setCreneau(newAlignment);
        // reload la liste des élèves
        loadStudentList(selectedDate,newAlignment);
    };

    function loadStudentList(date,cr) {
        console.log("Date sélectionnée : " + date);

        fetch('/backend/garderie/getStudents.php', {
            method: 'POST',
            headers: {
               'Authorization': `Bearer ${localStorage.getItem("token")}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ date: date , creneau : cr})
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Réponse réseau non OK');
                }
                return response.json();
            })
            .then(data => {
                console.log('Liste des élèves:', data);
                // on ajoute une propriété status à chaque élève si le statut n'est pas déjà défini
                data.forEach((student) => {
                    if (!student.status) {
                        student.status = 'DEFAULT';
                    }
                });
                setStudents(data);
            })
            .catch(error => {
                console.error('Problème avec la requête fetch:', error);
            });
    }
    function updateStudentStatus(studentId, newStatus) {
        // Logique pour supprimer l'élève de la base de données
       return fetch('/backend/garderie/API.php',{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body : JSON.stringify({date : selectedDate, student_id : studentId , action : "updateStatus",status : newStatus,creneau : creneau})
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Réponse réseau non OK');
                }
                return response.json();
            })
            .then(data => {
                if (data === 'ok') {
                    return true;
                } else {
                    console.log('Erreur lors de la suppression de l\'élève');
                    return false;
                }
            })
            .catch(error => {
                console.error('Problème avec la requête fetch:', error);
                // Gérer les erreurs ici
                return false;
            });

    }
    function updateStudentRetard(studentId,nb) {

        return fetch('/backend/garderie/API.php',{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body : JSON.stringify({date : selectedDate, student_id : studentId , action : "updateRetard",creneau : creneau,nb : nb})
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Réponse réseau non OK');
                }
                return response.json();
            })
            .then(data => {
                if (data === 'ok') {
                    return true;
                } else {
                    console.log('Erreur lors de la suppression de l\'élève');
                    return false;
                }
            })
            .catch(error => {
                console.error('Problème avec la requête fetch:', error);
                // Gérer les erreurs ici
                return false;
            });

    }

    function  addStudentToGarderie() {
        return fetch('/backend/garderie/API.php',{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ name: addStudent.name, firstName : addStudent.firstName, action: "add",date : selectedDate,creneau : creneau })
        })
            .then(response => response.json())
            .then(data => {
                return true
            })
            .catch(error => {
                console.error('Erreur:', error);
            return false
            });
    }

    const handleDeleteClick = (name) => {
        console.log('Suppression de l\'élève ' + name);
        setStudentSeleted(name);
        setOpenDialog(true);
    };
    const handleRetadClick = (name,retard) => {
        console.log('Retard de l\'élève ' + name + " " + retard);
        setStudentSeleted(name);
        setRetard(retard);
        setOpenDialogRetard(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
        setOpenDialogRetard(false);
        setStudentSeleted('');
        setOpenDialogAjout(false);
        setRetard(0);
        setChecked(false);
        setACI(false);
    };

    const handleConfirmDelete = () => {
        // checked est un booléen qui indique si la case est cochée
        handleStatusChange(studentSeleted, 'ABS' + (checked ? 'JUST' : ''));
        handleClose();
    };

    const handleConfirmRetard = () => {
        if (retard > 0){
            handleStatusChange(studentSeleted, "RETARD");
        }
        handleClose();
    };

    const [highlightedRow, setHighlightedRow] = useState(null);

    const handleStatusChange = (name, newStatus) => {
        // uppercase pour mettre en majuscule
        newStatus = newStatus.toUpperCase();
        let updatedRows = null;
        if (newStatus !== 'DEFAULT' && newStatus !== 'RETARD'){
            updateStudentStatus(name, newStatus);
             updatedRows = students.map(row =>
                row.student_id === name ? { ...row, status: newStatus,penalites:null } : row
            );
        }else if (newStatus === 'RETARD'){
            updateStudentRetard(name,retard);updatedRows = students.map(row =>
                row.student_id === name ? { ...row, status: newStatus,penalites : retard /15 } : row
            );
        }

        setStudents(sortRowsByStatus(updatedRows));
        setHighlightedRow(name);
        setTimeout(() => setHighlightedRow(null), 2000); // Réinitialise après 2 secondes
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 'PRESENT': return 'lightgreen';
            case 'RETARD': return 'yellow';
            case 'ABS': return 'lightcoral';
            case 'ABSJUST': return 'lightpink';
            case 'NI': return 'lightblue';
            default: return 'white';
        }
    };

    const sortRowsByStatus = (rows) => {
        const statusPriority = {
            'PRESENT': 2,
            'NI' : 2,
            'RETARD': 3,
            'ABS': 4,
            'ABSJUST' : 4,
            'default': 1
        };

        return rows.slice().sort((a, b) => {
            return statusPriority[a.status] - statusPriority[b.status];
        });
    };

    const getRowStyle = (row) => ({
        backgroundColor: getStatusColor(row.status),
        transition: 'background-color 0.5s ease',
        ...(highlightedRow === row.name && { boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)' })
    });

    // Cette fonction sera appelée lorsque la date change
    const handleDateChange = (selectedDates, dateStr, instance) => {
        setSelectedDate(dateStr); // Met à jour l'état de la date sélectionnée
        loadStudentList(dateStr,creneau);
    };

    function valuetext(value) {
        return `${value} min`;
    }

    function handleConfirmAjout() {
        addStudentToGarderie().then(r =>
            {
                loadStudentList(selectedDate,creneau);
                setOpenDialogAjout(false);
                setACI(false);
            }
        );

    }

    function handleClickAjoutButton() {
        setOpenDialogAjout(true);
        setACI(true);
    }

    const handleAutoCompleteChange = (selectedValue) => {
        setAddStudent(selectedValue);
    };


    return (
        <>
            <Logout />
            <Flatpickr
                value={selectedDate}
                onChange={handleDateChange}
                options={{
                    "disable": [
                        function(date) {
                            // return true to disable
                            return (date.getDay() === 0 || date.getDay() === 6 || date.getDay() === 3);

                        }
                    ],
                    locale: France,
                    dateFormat: "Y-m-d",
                    // autres options
                }}
            />

            <ToggleButtonGroup
                color="primary"
                value={creneau}
                exclusive true
                onChange={handleChangeButton}
                aria-label="Periode"
            >
                <ToggleButton value="MATIN">MATIN</ToggleButton>
                <ToggleButton value="MIDI">MIDI</ToggleButton>
                <ToggleButton value="SOIR">SOIR</ToggleButton>
            </ToggleButtonGroup>

            <Button onClick={handleClickAjoutButton} style={{ color: 'black'}}>Ajouter un eleve</Button>
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Nom</TableCell>
                        <TableCell align="center">Prénom</TableCell>
                        <TableCell align="center">Classe</TableCell>
                        <TableCell align="center">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {students.map((row) => (
                        <TableRow
                            key={row.name}
                            sx={getRowStyle(row)}>
                            <TableCell align="center" component="th" scope="row">
                                {row.name}
                            </TableCell>
                            <TableCell align="center">{row.firstName}</TableCell>
                            <TableCell align="center">{row.niveau}</TableCell>
                            <TableCell align="center" >
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Button
                                    color="success"
                                    onClick={() => handleStatusChange(row.student_id, 'PRESENT')}
                                    style={{minWidth : 32}}>
                                    ✓
                                </Button>
                                <Button
                                    color="error"
                                    onClick={() => handleDeleteClick(row.student_id)}
                                    style={{minWidth : 32}}>
                                    ✕
                                </Button>
                                <Button
                                    onClick={() => handleRetadClick(row.student_id,row.penalites * 15)}
                                    style={{minWidth : 32}}>
                                    +
                                </Button>
</div>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>

    <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">{"Confirmer l'absence"}</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Êtes-vous sûr de marquer {studentSeleted} comme absent ? <br />
                <Checkbox
                    checked={checked}
                    onChange={(e) => setChecked(e.target.checked)}
                />
                Absence justifiée
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} style={{ color: 'black'}}>Annuler</Button>
            <Button onClick={handleConfirmDelete} style={{ color: 'red'}} autoFocus>
                Valider
            </Button>
        </DialogActions>
    </Dialog>

            <Dialog
                open={openDialogRetard}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirmer le retard"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Veuillez sélectionner le retard de {studentSeleted} <br />
                        <Box>
                            <Slider
                                aria-label="Always visible"
                                getAriaValueText={valuetext}
                                valueLabelFormat = {valuetext}
                                onChange={(_, v) => setRetard(v)}
                                value = {retard}
                                valueLabelDisplay="auto"
                                step={15}
                                marks
                                min={0}
                                max={120}
                            />
                        </Box>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} style={{ color: 'black'}}>Annuler</Button>
                    <Button onClick={handleConfirmRetard} style={{ color: 'red'}} autoFocus>
                        Valider
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openDialogAjout}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Ajouter un élève"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Ajouter un élève <br /><br />
                        <AutoCompleteInput onValueChange={handleAutoCompleteChange} isActive={ACI} creneau={creneau} selectedDate={selectedDate} />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} style={{ color: 'black'}}>Annuler</Button>
                    <Button onClick={handleConfirmAjout} style={{ color: 'red'}} autoFocus>
                        Valider
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default StudentTable;