import React, {useEffect, useState} from 'react';
import {TextField, Button, Container, Typography, Box, InputAdornment, IconButton} from '@mui/material';
import Image from './img/login.png'; // Import using relative path
import { useNavigate  } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';


const LoginPage = ({ onLoginSuccess }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        document.title = 'Login';
        const listener = event => {
            if ( password !== '' && ( event.code === "Enter" || event.code === "NumpadEnter")) {
                console.log("Enter key was pressed. Run your function.");
                event.preventDefault();
                handleLogin();
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [password]);
    const handleLogin = async () => {
        try {
            const response = await fetch('/backend/login.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password }),
            });

            const data = await response.json();
            console.log(data);
            if (data.success) {
                setError(data.message);
                localStorage.setItem('role', data.role);
                // store the jwt token in the local storage
                localStorage.setItem('token', data.token);
                localStorage.setItem('user_id', data.user_id);
                if (data.role === 'prof') {
                    navigate('/StudentTable');
                }else if (data.role === 'parent'){
                    navigate('/dashboard');
                }
            } else {
                setError(data.message);
            }
        } catch (err) {
            setError('An error occurred.');
        }
    };


        const togglePasswordVisibility = () => {
            setShowPassword(!showPassword);

        };

    return (
        <Box
            sx={{
                minHeight: '100vh',
                position: 'relative', // Relative positioning for the pseudo-element
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundImage: `url(${Image})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                '&::before': { // Pseudo-element for blur effect
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backdropFilter: 'blur(3px)', // Apply the blur effect
                    zIndex: 1 // Place it behind the form
                }
            }}
        >
            <Container component="main" maxWidth="xs"
                       sx={{
                           mt: 1,
                           backgroundColor: 'white',
                           padding: 4,
                           borderRadius: 1,
                           boxShadow: 5,
                           position: 'relative', // Ensure the form is above the pseudo-element
                           zIndex: 2 // Higher than the pseudo-element
                       }}
            >
            <Typography variant="h4" style={{ margin: '20px 0' }}>Login</Typography>
            <TextField
                label="Username"
                fullWidth
                margin="normal"
                value={username}
                onChange={e => setUsername(e.target.value)}
            />
            <TextField
                label="Password"
                type={showPassword ? 'text' : 'password'}
                fullWidth
                margin="normal"
                value={password}
                onChange={e => setPassword(e.target.value)}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={togglePasswordVisibility}
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <Button variant="contained" color="primary" fullWidth onClick={handleLogin}>
                Login
            </Button>
            {error && <Typography color="error">{error}</Typography>}
        </Container>
        </Box>
    );
};

export default LoginPage;